import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMemo } from "react";

export const useGetRankWithSuffix = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const ordinals = {
      one: t('ordinal.one'),
      two: t('ordinal.two'),
      few: t('ordinal.few'),
      many: t('ordinal.many'),
      zero: t('ordinal.zero'),
      other: t('ordinal.other')
    };

    const pluralRules = new Intl.PluralRules(i18n.language, { type: 'ordinal' });

    return (rank: number) => `${rank}${ordinals[pluralRules.select(rank)]}`
  }, [i18n.language]);
};
